import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import { BsGraphDown } from "react-icons/bs";
import { GiNetworkBars } from "react-icons/gi";
import { GoDownload } from "react-icons/go";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon";
import Loader from "../../components/Loader.jsx";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useUser } from "../../components/UserProvider.js";

const ContactInformation = () => {
  const navigate = useNavigate();
  const { csn } = useCSN();
  const theme = useTheme();
  const [accountsname, setAccountsName] = useState(null);
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedAccount, setSelectedAccounts] = useState(null);
  const [selectedContractManager, setContractManager] = useState(null);
  

  const { token } = useUser();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

    const getAccountsData = () => { 
    axios.get(BACKEND_BASE_URL + `v1/api/account_dropdown/`, {headers})
    .then((response) => {
      if (response.status === 200) {
        const data = response.data?.accounts?.map(item => {
          const name = item.name;
          const csn = item.csn;
          const id = item.id;
          return {
            label: name,
            csn: csn,
            id: id,
          };
        });
        const uniqueLabels = new Set();

        const uniqueData = data.filter(item => {
          if (!uniqueLabels.has(item.csn)) {
            uniqueLabels.add(item.csn);
            return true;
          }
          return false;
        });
        setAccountsName(uniqueData)
      }
    })
   };
  useEffect(() => {
    if (token) {
      getAccountsData();
    }
  }, [token]);

  const handleSearch = (value) => {
    if (value) {
        axios
        .get(BACKEND_BASE_URL + `v1/api/get_contact_info/${value.id}`, { headers })
        .then((response) => {
            if (response.status === 200) {
              setSelectedAccounts(response?.data?.account);
              setContractManager(response?.data?.contract_manager);
            }
          });
    }
    else{
        setSelectedAccounts(null)
    }
  };

  return (
    <>
      <Box m="20px">
        <Box mb={10}>
          <Header title="Contact Information" />
        </Box>
        <Box sx={{ display: { md: "flex" } }}>
        <Box sx={{ minWidth: "200px" }}>
            {accountsname ? (
            <Autocomplete
                value={selectedValue}
                onChange={(event, newValue) => {
                setSelectedValue(newValue);
                handleSearch(newValue);
                }}
                options={accountsname}
                autoSelect
                getOptionLabel={(option) => `${option.label} (${option.csn})`}
                // getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select an Account"
                    variant="outlined"
                />
                )}
            />
            ) : (
            // <CircularProgress />
            <Autocomplete
                value={null}
                disabled 
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                <TextField
                    {...params}
                    label="Select an Account"
                    variant="outlined"
                    InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                        <>
                        {params.InputProps.endAdornment}
                        <Typography variant="body2" color="textSecondary">
                            Loading...
                        </Typography>
                        </>
                    ),
                    }}
                />
                )}
            />
            )}
            Please select an account to show contact info.
        </Box>
        </Box>
        {selectedAccount ? (              
          <Grid container mb={5} mt={1} spacing={3}>
            <Grid item xs={6}>
              <Box p={3} sx={{border:'1px solid gray',borderRadius:'8px'}}>
                <Typography
                  component={"h1"}
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  BD Person Contact
                </Typography>
                {selectedAccount.user_assign.length > 0 ? (
                  selectedAccount.user_assign.map(user => (
                    <div key={user.id}>
                      <Typography sx={{ fontSize: "20px" }}>
                        Name : {user.first_name} {user.last_name}
                      </Typography>
                      <Typography sx={{ fontSize: "20px" }}>
                        Email : {user.email}
                      </Typography>
                      <Typography sx={{ fontSize: "20px" }}>
                        Phone : {user.phone}
                      </Typography>
                    </div>
                  ))
                ) : (
                  <>Undefined</>
                )}
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box p={3} sx={{border:'1px solid gray',borderRadius:'8px'}}>
                <Typography
                  component={"h1"}
                  sx={{ fontSize: "30px", fontWeight: 600 }}
                >
                  Contract Manager
                </Typography>
                {selectedContractManager ? (
                  <>
                    <Typography sx={{ fontSize: "20px" }}>
                      Name : {selectedContractManager?.endCustomer_contractManager?.first}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      Email : {selectedContractManager?.endCustomer_contractManager?.email}
                    </Typography>
                    <Typography sx={{ fontSize: "20px" }}>
                      Phone : {selectedContractManager?.endCustomer_contractManager?.phone}
                    </Typography>
                  </>
                ) : (
                  <>Undefined</>
                )}
              </Box>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};

export default ContactInformation;
