import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import { useCSN } from "../../components/CSNProvider";
import { useUser } from "../../components/UserProvider";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv";
import axios from "axios";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";


const Dashboard = () => {
  const theme = useTheme();
  const smScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()


  const { csn } = useCSN();
  const {token, user} = useUser()
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeAccounts, SetactiveAccounts] = useState(null);
  const [inactiveAccounts, SetinactiveAccounts] = useState(null);
  const [totalAccounts, SetTotalAccounts] = useState(null);
  const [totalSalesInvoiceExcGst, SetTotalSalesInvoiceExcGst] = useState(null);
  const [renewalCount, SetrenewalCount] = useState(null);
  const [renewalDue, SetRenewalDue] = useState(null);
  
  const [campaignSent, SetcampaignSent] = useState(null);
  
  const [campaignUnsent, SetcampaignUnsent] = useState(null);
  const [coloumnChartData, SetColoumnChartData] = useState(null);
  const [seatDateChartData, setSeatDateChartData] = useState(null);
  
  console.log("🚀 ~ Dashboard ~ coloumnChartData:", coloumnChartData)
  const [seriesData, setSeriesData] = useState(null);
  const [ProductLine, setProduclLine] = useState(null);
  const [orderLoadingHo, SetOrderLoadingHo] = useState([]);
  const [paymentOverdue, SetPaymentOverdue] = useState(null);
  const [paymentReceived, SetPaymentReceived] = useState(null);
  const [invoicePending, SetInvoicePending] = useState(null);
  const [chartConfig, setChartConfig] = useState({
    series: [
      {
        name: "Seats",
        data: [],
      },
    ],
    options: {
      chart: {
        type: "line",
        height: 250,
        zoom: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        categories: [], // Dynamic categories
        title: {
          text: "Start Date",
        },
      },
      yaxis: {
        title: {
          text: "Seats",
        },
      },
      colors: ["#5372b9"],
      tooltip: {
        enabled: true,
      },
      legend: {
        show: true,
        position: "right",
      },
    },
  });
  console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ Dashboard ~ chartConfig:", chartConfig)
  
  



  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
    GetDashboardData();
    GetDashboardChart();
    GetSeatDateChart();
    }
  }, [csn, token]);
  
  useEffect (()=>{
    if (coloumnChartData){
    const allcities = Object.keys(coloumnChartData[Object.keys(coloumnChartData)[0]]);
    console.log("🚀 ~ useEffect ~ allcities:", allcities)
  
    const series_data = allcities.map(city => ({
      name: city,
      data: Object.keys(coloumnChartData).map(key => coloumnChartData[key]?.[city] || 0),
    }));
    console.log("🚀🚀🚀🚀 ~ useEffect ~ series_data:", series_data)
    setSeriesData(series_data)

    const product_line = Object.keys(coloumnChartData);
    console.log("🚀🚀🚀🚀🚀🚀🚀🚀 ~ useEffect ~ product_line:", product_line)
    setProduclLine(product_line)
    }

  },[coloumnChartData]);


  const GetDashboardData = () => {
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_dashboard_data${csn ? `/${csn}` : ''}`;
    axios.get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetactiveAccounts(response?.data.active_account);
          SetinactiveAccounts(response?.data.inactive_account);
          SetrenewalCount(response?.data.renewal_count);
          SetcampaignSent(response?.data.campaign_sent);
          SetcampaignUnsent(response?.data.campaign_unsent);
          SetTotalAccounts(response?.data.total_accounts);
          SetTotalSalesInvoiceExcGst(response?.data?.total_sales_invoice_amount_exc_gst);
          SetOrderLoadingHo(response?.data?.order_loading_ho);
          SetPaymentOverdue(response?.data?.payment_overdue);
          SetPaymentReceived(response?.data?.payment_received);
          SetInvoicePending(response?.data?.invoice_pending);
          SetRenewalDue(response?.data?.subscriptions_last_30_days);
          
          
          
          
          setIsLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const chartData = {
    options: {
      chart: {
        id: 'seat-date-chart',
        type: 'line',
      },
      xaxis: {
        categories: seatDateChartData?.map(item => item['start Date']),
        title: {
          text: 'Start Date',
        },
      },
      yaxis: {
        title: {
          text: 'Seats',
        },
      },
      title: {
        text: 'Seats Over Time',
        align: 'center',
      },
    },
    series: [
      {
        name: 'Seats',
        data: seatDateChartData?.map(item => item.seats),
      },
    ],
  };


  const GetSeatDateChart = async () => {
    try {
      setIsLoading(true);
      const apiUrl = `${BACKEND_BASE_URL}v1/api/get_seats_date_chart${
        csn ? `/${csn}` : ""
      }`;

      const response = await axios.get(apiUrl, { headers });
      if (response.status === 200 && response.data) {
        const responseData = response?.data;

        setSeatDateChartData(responseData);

        toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("No data available.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSeatDateChartData([]);
      }
    } catch (error) {
      toast.error("Error fetching chart data.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } finally {
      setIsLoading(false);
    }
  };





  const GetDashboardChart = () => {
    setIsLoading(true)
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_dashboard_chart${csn ? `/${csn}` : ''}`;
    axios.get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          if (response?.data && response?.data?.Response) {
            console.log("_________________________if________________-");
            
            // If data is present and has a Response property, set it to coloumnChartData
            SetColoumnChartData(response?.data?.Response || null);
          } else {
            console.log("_________________________if________________-");
            // If data is not present or does not have a Response property, set coloumnChartData to null
            SetColoumnChartData(null);
          }
          // SetColoumnChartData(response?.data?.Response)
          // SetColoumnChartData(response?.data?.Response || null);

          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };



  const ChartData = {
    series: [campaignSent || '', campaignUnsent || ''],
    options: {
      chart: {
        type: "donut",
        events: {
          click(event, chartContext, config) {
          },
        },
      },
      colors: ["#5372b9", "#90be82", "#fdc66d", "#fa7870", "#6eb8d8"],
      labels: ["Campaign Sent", "Campaign Unsent"],
      legend: {
        show: true,
        position: "right",
      },
     
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 60,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const ColoumnCharts = {
    series : seriesData || [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: true
        },
        zoom: {
          enabled: true
        }
      },
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0
          }
        }
      }],
      
      xaxis: {
        categories: ProductLine || []
      },
      legend: {
        position: 'right',
        offsetY: 40
      },
      fill: {
        opacity: 1
      }
    },
  }


  return (
    <>
    <Box m="20px">

      <Box
        display={smScreen ? "flex" : "block"}
        flexDirection={smScreen ? "row" : "column"}
        justifyContent={smScreen ? "space-between" : "start"}
        alignItems={smScreen ? "center" : "start"}
        m="10px 0"
      >
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <DownloadOutlinedIcon sx={{ mr: "10px" }} />
            Download Reports
          </Button>
        </Box>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      {user.user_type === "Client" ? (
        <>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
              onClick={() => navigate('renew_history')}
              style={{ cursor: 'pointer' }}
            >
              {renewalDue == null ? (
                <>Loading........</>
              ) : (
              <StatBox
                title={renewalDue}
                subtitle="Renewal Due (Within 30 days)"
                increase="14%"
                icon={
                  <EmailIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
              )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {renewalCount == null ? (
                <>Loading........</>
              ) : (
              <StatBox
                title={totalSalesInvoiceExcGst}
                subtitle="Total Annual License Amount (Exc GST)"
                increase="+21%"
                icon={
                  <PointOfSaleIcon
                    sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                  />
                }
              />
              )}
            </Box>
          </Grid>
        </>) 
        : (<>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('renew_history')}
            style={{ cursor: 'pointer' }}
          >
            {renewalCount == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={renewalCount}
              subtitle="Renewal Email Sent"
              increase="14%"
              icon={
                <EmailIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renewalCount == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={totalSalesInvoiceExcGst}
              subtitle="Sales Invoice Exc GST"
              increase="+21%"
              icon={
                <PointOfSaleIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        </>)}

        
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('active_account')}
            style={{ cursor: 'pointer' }}
          >
            {activeAccounts == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={activeAccounts}
              subtitle="Active Accounts"
              increase={`${((activeAccounts / totalAccounts) * 100).toFixed(2)}%`}
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('inactive_account')}
            style={{ cursor: 'pointer' }}
          >
            {inactiveAccounts == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={inactiveAccounts}
              subtitle="Inactive Accounts"
              increase={`${((inactiveAccounts / totalAccounts) * 100).toFixed(2)}%`}
              icon={
                <TrafficIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        

        {user.user_type === "Superadmin" && (
          <>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('#')}
            style={{ cursor: 'pointer' }}
          >
            {paymentOverdue == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={`₹${paymentOverdue}`}
              subtitle="Payment Overdue"
              increase="14%"
              icon={
                <EmailIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {renewalCount == null ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={0}
              subtitle="Payment Outstanding"
              increase="+21%"
              icon={
                <PointOfSaleIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('#')}
            style={{ cursor: 'pointer' }}
          >
            {paymentReceived ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={`₹${paymentReceived}`}
              subtitle="Payment Recieved"
              increase={`${((activeAccounts / totalAccounts) * 100).toFixed(2)}%`}
              icon={
                <PersonAddIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
          <Box
            width="100%"
            backgroundColor={colors.primary[400]}
            display="flex"
            alignItems="center"
            justifyContent="center"
            onClick={() => navigate('#')}
            style={{ cursor: 'pointer' }}
          >
            {invoicePending ? (
              <>Loading........</>
            ) : (
            <StatBox
              title={invoicePending}
              subtitle="Invoice Pending"
              increase={`${((inactiveAccounts / totalAccounts) * 100).toFixed(2)}%`}
              icon={
                <TrafficIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
            )}
          </Box>
        </Grid>
        </>
        )}
        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={8}
          container
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {user.user_type === "Client" ? (<>
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Licence Asset Graph
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.greenAccent[500]}
                  >
                    $58,373,698
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0">
                <LineChart isDashboard={true} />
              </Box>
            </Box>
          </Grid>
          </> ):(
            <>
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]}>
              <Box
                mt="25px"
                p="0 30px"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.grey[100]}
                  >
                    Revenue Generated
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="600"
                    color={colors.greenAccent[500]}
                  >
                    $58,373,698
                  </Typography>
                </Box>
                <Box>
                  <IconButton>
                    <DownloadOutlinedIcon
                      sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box height="250px" m="-20px 0 0 0">
              <ReactApexChart
                options={chartData.options}
                series={chartData.series}
                type="line"
                height={250}
              />
              </Box>
            </Box>
          </Grid>
            </>)}

          
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]} p="22px">
              <Typography variant="h5" fontWeight="600">
                Campaign
              </Typography>
              {campaignSent == null && campaignUnsent == null ? (
                  <>Loading data...</>
                  ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt="25px"
              >
                
                    <ReactApexChart
                      options={ChartData?.options}
                      series={ChartData?.series}
                      type="donut"
                      width={320}
                    />
                <Typography
                  variant="h5"
                  color={colors.greenAccent[500]}
                  sx={{ mt: "15px" }}
                >
                  {campaignSent} campaign is successfully sent
                </Typography>
                <Typography>
                  Includes extra misc expenditures and costs
                </Typography>
              </Box>
            )}
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6}>
            <Box backgroundColor={colors.primary[400]}>
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ padding: "30px 30px 0 30px" }}
              >
                Seat Quantity
              </Typography>
              {coloumnChartData ? (
                <>
                <Box height="250px" mt="20px">
                {seriesData && ProductLine ? (
                    <ReactApexChart
                      options={ColoumnCharts?.options}
                      series={ColoumnCharts?.series}
                      type="bar"
                      height={250}
                    />
                  ) : (
                    <>Loading.....</>
                  )}
                </Box>
                </>
              ) : (
                <>
                <Box height="250px" mt="20px">
                  No data
                </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid xs={12}>
            <Box backgroundColor={colors.primary[400]} padding="30px">
              <Typography
                variant="h5"
                fontWeight="600"
                sx={{ marginBottom: "15px" }}
              >
                Geography Based Traffic
              </Typography>
              <Box height="200px">
                <GeographyChart isDashboard={true} />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} md={4} lg={4} xl={4}>
          <Box
            backgroundColor={colors.primary[400]}
            maxHeight="100vh"
            overflow="auto"
            m="25px 0 0 0"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              color={colors.grey[100]}
              p="15px"
            >
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Resent Transaction
              </Typography>
            </Box>
            {orderLoadingHo.map((transaction, i) => {
              return (
                <Box
                  key={`${transaction}-${i}`}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  borderBottom={`4px solid ${colors.primary[500]}`}
                  p="15px"
                  // onClick={() => navigate(`update_order_loading_po/${transaction.id}`)}
                  onClick={!transaction.locked ? () => navigate(`/update_order_loading_po/${transaction.id}`) : null}
                  style={{
                    cursor: transaction.locked ? 'not-allowed' : 'pointer'
                  }}
                >
                  <Box>
                    <Typography
                      variant="h5"
                      fontWeight="600"
                      color={colors.greenAccent[100]}
                      
                    ><Tooltip title={transaction?.account_name || ''} >
                      {`${transaction?.account_name.slice(0, 14)}...`}
                      </Tooltip>
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {transaction?.bd_person_name}
                    </Typography>
                  </Box>
                  
                  <Box  color={colors.grey[100]}>{transaction?.order_loading_date}</Box>
                  <Box
                    color={colors.greenAccent[500]}
                    p="5px 10px"
                    borderRadius="4px"
                  >
                    ₹{transaction?.sales_invoice_amount_ex_gst || 0}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </Box>
      {
        isLoading && <Loader />
      }

{/* <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="line"
          height={350}
        /> */}
    </>
  );
};

export default Dashboard;
