import React, { useState, useEffect } from "react";
import Header from "../../components/Header.jsx";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useCSN } from "../../components/CSNProvider.jsx";
import { Box, Typography, useTheme, Modal } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import moment from 'moment';
import { MdClose } from "react-icons/md";


const ExportedFiles = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { token } = useUser();
  const [exportedFiles, SetExportedFiles] = useState([]);
  console.log("🚀 ~ ExportedFiles ~ exportedFiles:", exportedFiles)
  const [loading, SetLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const [selectedExportedFile, SetSelectedExportedFile] = useState(null);
  const [open, setOpen] = useState(false);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const { csn } = useCSN();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetExportedHistory()
    }
  }, [csn,token]);

  const GetExportedHistory = () => {
    axios.get(BACKEND_BASE_URL + `v1/api/exported_files/${csn ? `${csn}` : ''}`, { headers })
      .then((response) => {
        if (response.status === 200) {
            SetExportedFiles(response?.data || [])
            SetLoading(false)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  // const HandleViewDetails = (id) => {
  //   setOpen(true);
  //   axios.get(BACKEND_BASE_URL + `v1/api/renewal/${id}`, { headers })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         SetSelectedExportedFile(response?.data);
  //         setError(null);
  //         toast.success("Data Loaded", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       } else {
  //         toast.error("Error occured while displaying accounts.", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     })
  //     .catch(() => {
  //       setError(error?.response.data.Response.message);
  //       SetLoading(false);
  //       toast.error("Error occured while displaying accounts.", {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
        
  //     });
  // };

const FormattedDateCell = ({ value }) => {
  const formattedDate = moment(value).format('MMMM DD, YYYY hh:mm:ss A');
  return <div>{formattedDate}</div>;
};

const getRowId = (row) => row.id;


const columns = [
  {
    field: "name",
    headerName: "File Name",
    width: 250,
    flex:1,
    renderCell: (params) => (
      <div>
        {params.value ? (
          <span className="text-blue-600 font-semibold">{params.value}</span>
        ) : (
          <span style={{ color: "red" }}>Unnamed File</span>
        )}
      </div>
    ),
  },
  {
    field: "user_name",
    headerName: "Uploaded By",
    width: 180,
    flex:1,
    renderCell: (params) => (
      <div>
        {params.value ? (
          <span>{params.value}</span>
        ) : (
          <span style={{ color: "red" }}>Unknown User</span>
        )}
      </div>
    ),
  },
  {
    field: "created_at",
    headerName: "Date Uploaded",
    width: 180,
    flex:1,
    renderCell: (params) => {
      const date = new Date(params.value);
      return (
        <span>{!isNaN(date) ? date.toLocaleDateString() : "Invalid Date"}</span>
      );
    },
  },
  {
    field: "file",
    headerName: "Download",
    width: 150,
    flex:1,
    renderCell: (params) => (
      <div>
        {params.value ? (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            className="action-button bg-blue-500 text-white px-3 py-1 rounded"
          >
            Download
          </a>
        ) : (
          <span style={{ color: "red" }}>No File</span>
        )}
      </div>
    ),
  },
];



  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Exported Files" subtitle="welcome to you Team" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
        {loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <DataGrid
            rows={exportedFiles}
            columns={columns}
            getRowId={getRowId}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection 
            disableRowSelectionOnClick
            
          />
        )}
      </Box>
    </Box>
      <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    classes={{ backgroundColor: "red" }}
  >
    <div className="relative -translate-x-2/4 bg-white h-[300px] max-w-[800px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
      <div className="absolute top-2 right-3 cursor-pointer">
        <MdClose
          className="cursor-pointer text-xl"
          onClick={() => {
            setOpen(false);
            SetSelectedExportedFile(null);
          }}
        />
      </div>
      <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
        Details Page
      </h3>
      <div>
          <p>Recipient: {selectedExportedFile?.recipient}</p>
          <p>Subject: {selectedExportedFile?.subject}</p>
          <p>Message : </p><div dangerouslySetInnerHTML={{ __html:selectedExportedFile?.message_body}} />
          <p>Status: {selectedExportedFile?.status}</p>
        </div>
          
    </div>
    </Modal>
    </>
  );
};

export default ExportedFiles;
